import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/utilities/translation.service';

@Pipe({
  name: 'passedTime'
})
export class PassedTimePipe implements PipeTransform {
  constructor(private ts:TranslationService){}

  transform(timestamp: string | Date,typeString:string|null): string {
    if (!timestamp) {
      return 'Invalid timestamp';
    }

    const now = new Date();
    const inputDate = new Date(timestamp);

    // Validate the timestamp
    if (isNaN(inputDate.getTime())) {
      return 'Invalid timestamp';
    }

    const diffInSeconds = Math.abs(Math.floor((now.getTime() - inputDate.getTime()) / 1000));
    const isFuture = inputDate > now; // Determine if the timestamp is in the future
    let timeKey ='generic.'
    timeKey+= isFuture ? 'willBePublished' : typeString=='draft'?'hasBeenCreated':'hasBeenPublished';

    // Define time units in seconds
    const timeUnits = [
      { unit: 'year', seconds: 31536000 }, // 365 days
      { unit: 'month', seconds: 2592000 }, // 30 days
      { unit: 'week', seconds: 604800 }, // 7 days
      { unit: 'day', seconds: 86400 }, // 24 hours
      { unit: 'hour', seconds: 3600 }, // 60 minutes
      { unit: 'minute', seconds: 60 }, // 60 seconds
      { unit: 'second', seconds: 1 },
    ];
    for (const timeUnit of timeUnits) {
      let count = Math.floor(diffInSeconds / timeUnit.seconds);
      let countStr=count.toString();
      if (count >= 1) {
        if(count==1){
          if(timeUnit.unit == 'year'||timeUnit.unit == 'month'||timeUnit.unit == 'day'){
            countStr='generic.oneUnitM'
          }
          else{
             countStr='generic.oneUnitF'
          }
        }
        return `${this.ts.t(timeKey)} ${this.ts.t(countStr)} ${this.ts.t('generic.'+timeUnit.unit+ (count > 1 ? 's' : ''))}`;
      }
    }

    return 'Just now';
  }

}
