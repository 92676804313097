import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

@Component({
  selector: 'app-platform-filter',
  templateUrl: './platform-filter.component.html',
  styleUrls: ['./platform-filter.component.scss'],
})
export class PlatformFilterComponent {
  constructor(private uiStateService: UiStateService) {}

  @Input() currentPlatforms: any = {};
  @Input() currentDateRange: any;
  ngOnInit() {
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      console.log("post filters",data)
      this.currentPlatforms = data;
    });
    this.uiStateService.dateRangeUpdate$.subscribe((data) => {
      console.log("post filters",data)
      this.currentDateRange = data;
    });
  }
  

  toggleGoogle(): void {
    this.togglePlatform('isGoogleON');
  }

  toggleFacebook(): void {
    this.togglePlatform('isFacebookON');
  }
  
  toggleLinkedIn(): void {
    this.togglePlatform('isLinkedinON');
  }
  
  toggleInstagram(): void {
    this.togglePlatform('isInstagramON');
  }

  private togglePlatform(platformKey: string): void {
    this.currentPlatforms[platformKey] = !this.currentPlatforms[platformKey];
    this.uiStateService.updatePlatformsFilter(this.currentPlatforms);
    //this.uiStateService.pla.next(this.currentPlatforms);
    return this.currentPlatforms[platformKey]
  }
  CheckedCount(){
    return Object.values(this.currentPlatforms).filter(value => value === true).length;
  }
}
