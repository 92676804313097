<div *ngIf="isVisible" class="p-10 text-sm w-full h-auto flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
    <span
      class="absolute top-0 right-0 mt-2 mr-3 text-md font-bold hover:text-pink-600 cursor-pointer"
      (click)="close()"
    >✕</span>
    
    <div class="overflow-auto w-full">
      <table class="table-auto border-collapse w-full">
        <!-- Row 1: Input File -->
        <tr >
          <td colspan="4" class="" [ngClass]="{'opacity-0':!isInputVisibile(),'opacity-1':isInputVisibile()}">
            <div class="relative w-full flex items-center">
                <!-- Hidden file input -->
                <input
                  #fileInput
                  type="file"
                  class="hidden"
                  (change)="fileChangeEvent($event)"
                />
              
                 <!-- Custom Button -->
                <button
                type="button"
                class="p-3 ml-5 rounded-lg bg-blue-500 text-white font-semibold hover:bg-blue-600 transition duration-300"
                (click)="triggerFileInput()"
                >
                {{'generic.uploadImage'|translate}}
                </button>

                <!-- File Name/Placeholder Text -->
                <p *ngIf="!imageChangedEvent" class="text-sm text-gray-500">
                {{'generic.noFileChosen'|translate}}
                </p>

                <p *ngIf="imageChangedEvent" class="ml-5 text-sm text-green-500">
                {{'generic.fileSelected'|translate}}: {{ fileName }}
                </p>
              </div>
          </td>
        </tr>
  
        <!-- Row 2: Image Cropper and Placeholder -->
        <tr>
          <td colspan="3" rowspan="2" class="p-4 w-3/4">
            <div *ngIf="!imageChangedEvent" class="flex flex-col items-center justify-center p-10 bg-gray-200 border-2 border-gray-400 border-dashed rounded-3xl cursor-pointer" (click)="triggerFileInput()">
                <input type="hidden" (change)="fileChangeEvent($event)">
              <p class="text-gray-600 mb-2">{{'generic.noFileChosen'|translate}}</p>
              <p class="text-sm text-gray-500">{{'generic.uploadImage'|translate}}</p>
            </div>
  
            <image-cropper *ngIf="imageChangedEvent"
              class="rounded-md m-auto"
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1" 
              [resizeToWidth]="resizeToWidth"
              format="png"
              [roundCropper]="true"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded($event)"
              style="margin: auto;"
             />
          </td>
        </tr>
  
        <!-- Row 3: Cropped Preview -->
        <tr>
          <td class="p-4  text-center flex items-start">
            <div *ngIf="croppedImage">
              <p class="mb-2 text-gray-600">{{'generic.croppedPreview'|translate}}</p>
              <img
                [src]="croppedImage"
                alt="Cropped Image"
                class="rounded-full border w-full"
              />
              <button
                class="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                (click)="confirmCroppedImage()"
              >{{'generic.confirm'|translate}}</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  