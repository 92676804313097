import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debug } from 'src/app/utils/debug';
import {ICON} from "src/app/interfaces/ModalIcon"

@Component({
	selector: 'app-more-hours',
	templateUrl: './more-hours.component.html',
	styleUrls: ['./more-hours.component.scss']
})
export class MoreHoursComponent {
	@Input() moreHours: any;
	@Input() moreHoursTypes: any;
	@Output() moreHoursChange = new EventEmitter<any>();
	allDays: string[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

	newMoreHoursPeriods: any[] = []
	groupedMoreHours: any[] = []
	expandedIndex: number | null = null;
	choosedItemIndex: number | null = null ;
	isModalOpen: boolean = false;
  modalIcon: ICON= ICON.delete;
  heading: string = '';
  subHeading: string = '';
	

	constructor(
		public translate: TranslateService,
		private cdr: ChangeDetectorRef
	)	{}

	ngOnInit(){
		this.initGroupedMoreHours();
	}

	initGroupedMoreHours(){
		if (!this.moreHours || this.moreHours.length == 0) {
			this.moreHours = [];
    } else {
			this.groupedMoreHours = this.moreHours.map((item:any) => {
				const daysMap: { [key: string]: any } = {};

				// Initialize all days to closed
				this.allDays.forEach(day => {
					daysMap[day] = {
						day,
						closed: true,
						allDay: false,
						periods: []
					};
				});

				// Map the existing periods from moreHours
				item.periods.forEach((period:any) => {
					const day = period.openDay;
					
					if (!daysMap[day]) {
						daysMap[day] = {
							day,
							closed: false,
							allDay: Object.keys(period.openTime).length === 0 && Object.keys(period.closeTime).length === 0,
							periods: []
						};
					} else {
						daysMap[day].closed = false;  // Mark the day as open
						if (Object.keys(period.openTime).length > 0 && Object.keys(period.closeTime).length > 0) {
								daysMap[day].periods.push({
										openTime: this.formatTime(period.openTime),
										closeTime: this.formatTime(period.closeTime)
								});
						}
						daysMap[day].allDay = daysMap[day].periods.length === 0; // Determine if it's all day or not
					}
				});

				return {
					hoursTypeId: item.hoursTypeId,
					days: Object.values(daysMap)
				};
			});

    }
	}

	addMoreHour() {
		const newMoreHour: any = {
			hoursTypeId: '',
			closed: false,
			allDay: false,
			days: this.fillClosedDays()
		};
		this.newMoreHoursPeriods.push(newMoreHour);
		// this.specialHours.specialHourPeriods.push(newMoreHour);
	}

	createNewMoreHour(i: number) {
		this.groupedMoreHours.push(this.newMoreHoursPeriods[i]);
		this.cancelMoreHourCreation(i);
		this.transformGroupedToMoreHours(); // Update moreHours
	}

	cancelMoreHourCreation(index: number) {
		this.newMoreHoursPeriods.splice(index, 1);
		// this.emitChanges();
	}

	toggleForm(index:number) {
		if (this.expandedIndex === index) {
			this.expandedIndex = null; 
		} else {
			this.expandedIndex = index; 
		}
	}

	getTranslatedDay(day: string): string {
		return this.translate.instant(`generic.Days.${day}`);
	}

	onTimeChange(event: any){
		this.transformGroupedToMoreHours()
	}


	toggleClosed(day: any, moreHourIndex: number, type: string | null = null) {

    day.closed = !day.closed;
		if (type && type == "new") {
			this.newMoreHoursPeriods[moreHourIndex].days.find((item:any) => item.day === day.day).closed = !this.newMoreHoursPeriods[moreHourIndex].days.find((item:any) => item.day === day.day).closed
		} else {
			this.groupedMoreHours[moreHourIndex].days.find((item:any) => item.day === day.day).closed = !this.groupedMoreHours[moreHourIndex].days.find((item:any) => item.day === day.day).closed
		}
    if (!day.closed && day.periods.length == 0) {
      this.addTimePeriod(day);
    }
		this.transformGroupedToMoreHours(); // Update moreHours
  }

	toggleAllDay(day: any, moreHourIndex: number, type: string | null = null) {

    day.allDay = !day.allDay;
		if (type && type == "new") {
			this.newMoreHoursPeriods[moreHourIndex].days.find((item:any) => item.day === day.day).allDay = !this.newMoreHoursPeriods[moreHourIndex].days.find((item:any) => item.day === day.day).allDay
		} else {
			this.groupedMoreHours[moreHourIndex].days.find((item:any) => item.day === day.day).allDay = !this.groupedMoreHours[moreHourIndex].days.find((item:any) => item.day === day.day).allDay
		}
    if (!day.allDay && day.periods.length == 0) {
      this.addTimePeriod(day);
    }
    this.transformGroupedToMoreHours(); // Update moreHours
  }

  addTimePeriod(day: any) {
    day.periods.push({ openTime: '', closeTime: '' });
    this.emitChanges();
  }

  removeTimePeriod(day: any, periodIndex: number) {
    day.periods.splice(periodIndex, 1);
    this.emitChanges();
  }

	// modal
	closeModal(){
		this.isModalOpen = false;
	}

	removeMoreHour(index: number) {
		// this.subHeading = 'generic.confirmUserDeletionMsg';
		this.heading = 'generic.confirmDeletion';
		this.modalIcon = ICON.delete;
		this.isModalOpen = true;
		this.choosedItemIndex = index;
	}

	deleteSpecialHour(){
		if (this.choosedItemIndex !== null && this.choosedItemIndex !== undefined) {
			this.moreHours.splice(this.choosedItemIndex, 1);
			this.groupedMoreHours.splice(this.choosedItemIndex, 1);
			this.expandedIndex = null;
			this.transformGroupedToMoreHours(); // Update specialHours
		}
		this.closeModal();
		this.choosedItemIndex = null;
	}

	formatTime(time: any): string {
    const hours = time.hours !== undefined ? time.hours : '00';
    const minutes = time.minutes !== undefined ? time.minutes : '00';
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
	};

	fillClosedDays(){
		return this.allDays.map((day:string) => {
			return {
				day: day,
				closed: true,
				allDay: false,
				periods: []
			}
		})
	}

	emitChanges() {
    this.moreHoursChange.emit(this.moreHours);
  }

	transformGroupedToMoreHours() {

		this.moreHours = this.groupedMoreHours.map(item => {
			const periods: any[] = [];
	
			item.days.forEach((day: any) => {
				if (!day.closed) {
					// If the day is not closed, iterate over periods
					if (day.allDay) {
						// Handle allDay case by setting openTime to empty and closeTime to 24:00
						periods.push({
							openDay: day.day,
							openTime: {},
							closeDay: day.day,
							closeTime: { hours: 24 }
						});
					} else {
						// Handle normal periods
						day.periods.forEach((period: any) => {
							const [openHours, openMinutes = 0] = period.openTime.split(':').map(Number);
							const [closeHours, closeMinutes = 0] = period.closeTime.split(':').map(Number);
	
							periods.push({
								openDay: day.day,
								openTime: { hours: openHours, ...(openMinutes ? { minutes: openMinutes } : {}) },
								closeDay: day.day,
								closeTime: { hours: closeHours, ...(closeMinutes ? { minutes: closeMinutes } : {}) }
							});
						});
					}
				}
			});
	
			return {
				hoursTypeId: item.hoursTypeId,
				periods
			};
		});

		this.emitChanges();
	}
}
