import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
  @Input() imageChangedEvent: any = null;
  @Input() croppedImage: any = ''; // Accept croppedImage from parent
  @Input() isVisible: boolean = false;
  @Output() onPanelClosed = new EventEmitter();
  @Output() onImageConfirmed = new EventEmitter();
  resizeToWidth:number = 500;
  aspectRatio:number = 1;
  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  fileName:string=""
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      const file = event.target.files[0]
      if (file) {
        this.fileName = file.name; // Assign the file name
        console.log('Selected file name:', this.fileName);
      }
  }


  isInputVisibile(){
    return this.imageChangedEvent!=null;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.objectUrl; 
    // console.log('Cropped Event:', event); // Debug log to inspect event 
  }

  @ViewChild('fileInput') fileInput!: ElementRef;
  triggerFileInput(){
    this.fileInput.nativeElement.click();
  }
  imageLoaded(image: LoadedImage) {
    const imgElement = document.querySelector('.ngx-ic-source-image.ng-star-inserted') as HTMLElement;

    if (imgElement) {
      imgElement.style.margin = 'auto';
      imgElement.style.display = 'block'; // Ensure it's centered in its container
      console.log('Style applied to image:', imgElement);
    }
    this.resizeToWidth = Math.max(image.transformed.size.width, 300); // Set a minimum width
    this.aspectRatio = image.transformed.size.width / image.transformed.size.height;
}

  cropperReady() {
  }
  loadImageFailed() {
      // show message
  }
  lastCroppedImageUrl=''
  confirmCroppedImage(){
    this.onImageConfirmed.emit({blob:this.croppedImage,name:"cropped_"+this.fileName,MimeType:'image/'+this.fileName.slice(-3)});
    this.close()
  }

  close(){
    this.isVisible = false;
    this.onPanelClosed.emit()
  }
}
