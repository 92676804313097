import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IResponse } from 'src/app/interfaces/IResponse';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { GroupService } from 'src/app/services/repositories/group.service';
import { UserService } from 'src/app/services/repositories/user.service';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { Debug } from 'src/app/utils/debug';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-global-settings',
  templateUrl: './globalSettings.component.html',
  styleUrls: ['./globalSettings.component.scss'],
})
export class GlobalSettingsComponent {
  constructor(
    private userService: UserService,
    private groupService: GroupService,
    private translationService: TranslationService,
    private socialMediaAPI: SocialMediaAPIService
  ) {}
  env: string = environment.env;
  groups: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };

  users: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };

  Profiles: IResponse = {
    data: [],
    success: true,
    error: '',
    status: 0,
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
  };

  AppPermissions: { id: number; action: string }[] = [];
  AppAccreditations: any[] = [];
  isModalOpen: boolean = false;
  head: string = '';
  subHead: string = '';
  modalIcon: ICON = ICON.success;

  ngOnInit() {
    //get all group
    this.groupService.getGroups().subscribe((res) => {
      this.groups = res;
    });

    //get all profiles
    this.userService.getAllProfiles().subscribe((res) => {
      this.Profiles = res;
      this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
    });

    //get all users
    this.userService.getAllUsers().subscribe((res) => {
      this.users = res;
    });

    //get all permissions
    this.userService.getAllPermissions().subscribe((res) => {
      this.AppPermissions = res.data;
    });

    //get All Accreditations
    this.userService.getAllAccreditations().subscribe((res) => {
      this.AppAccreditations = res.data;
      this.accreditationsDisplay =
        this.prepareAccreditationsForTableDisplay(res);
    });
  }

  fetchJobs() {
    // Fetch jobs from your API
    this.jobs = [];
    this.socialMediaAPI.fetchBullJsQueues().subscribe((res: any) => {
      // Add jobs to the array
      this.jobs.push({
        ...res.postQueue,
        label: '[API] save created Post on socialmedia page',
        name: 'postQueue',
      });
      this.jobs.push({
        ...res.syncPostQueue,
        label: '[API] get All post and page insights',
        name: 'syncPostQueue',
      });
      this.jobs.push({
        ...res.postAnalyticsQueue,
        label: '[API] get posts individual insights',
        name: 'postAnalyticsQueue',
      });
      this.jobs.push({
        ...res.passwordResetTokenQueue,
        label: '[Email] Password reset request deleter',
        name: 'passwordResetTokenQueue',
      });
      this.jobs.push({
        ...res.connectedUsersQueue,
        label: '[Token] Delete user from connected table if token is expired',
        name: 'connectedUsersQueue',
      });
    });
  }
  jobs: any = [];

  clearJobs(queueName: string, group: string) {
    console.log(queueName, group);
    this.socialMediaAPI
      .clearJob(queueName, group)
      .subscribe((response: any) => {
        // Handle the response, like updating the UI or displaying a success message
        console.log(`${queueName} jobs cleared for ${queueName}`);
        this.fetchJobs(); // Optionally refresh job data
      });
  }
  //------------------------------------------------------------user methods
  @ViewChild('userForm') userForm!: NgForm;

  checkIfFormIsDirty() {
    return this.userForm.dirty;
  }

  tmpUser = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  onUpdateUsers() {
    this.userService
      .getDataByPage(
        this.users.navigation.page,
        this.users.navigation.itemsPerPage
      )
      .subscribe((res) => {
        Debug.log('request the updated changes', res);
        this.users = res;
      });
  }

  editUser(data: any) {
    Debug.log('editing user', data);
    this.userService.updateUser(data.item.id, data.item).subscribe();
  }

  deleteUser(data: any) {
    this.userService.deleteUser(data.item.id).subscribe();
    this.users.data = this.users.data.filter((u: any) => u.id != data.item.id);
    //todo update the accreditations
  }

  //------------------------------------------------------------profile methods
  tmpProfile: string = '';
  CreateProfile() {
    const tmpProfile = this.tmpProfile.toLocaleLowerCase().trim();
    Debug.log('createing profile');
    if (!this.alreadyExists && tmpProfile.length >= 3) {
      this.userService.newProfile(tmpProfile).subscribe((res) => {
        this.Profiles = res;
				this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
        this.tmpProfile = '';
      });
    }
    (this.head = 'generic.successCreateProfile'), (this.subHead = '');
    this.isModalOpen = true;
    setTimeout(() => {
      this.isModalOpen = false;
    }, 1500);
  }
  alreadyExists: boolean = false;
  checkifprofileExists(data: any) {
    const currentValue = data.target.value.toLocaleLowerCase().trim();
    this.alreadyExists = this.Profiles.data.some((profile: any) => {
      return profile.name === currentValue;
    });
    // Debug.log(this.alreadyExists,currentValue,this.Profiles.data.some((profile:any)=>{profile.name==currentValue}))
  }
  editProfile(data: any) {
    Debug.log('editingProfile', data);
    this.userService.updateProfile(data.item.id, data.item).subscribe();
    //TODO fetch the new profiles list
  }

  deleteProfile(data: any) {
    Debug.log('deleting profile', data);
    this.userService.deleteProfile(data.item.id).subscribe((res) => {
			console.log("deleteProfile res", res)
		})
    // this.Profiles.data = this.Profiles.data.filter(
    //   (p: any) => p.id != data.item.id
    // );
		this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
    //TODO fetch the new profiles list
  }

  //------------------------------------------------------------permissions methods
  isPermissionsFormDirty: boolean = false;
  UpdatePermissions(data: any) {
    Debug.log('permissions changes', data);
    //checking if dirty will be based on the fact that this.selectedProfile.permissions and data has same value or not
    this.isPermissionsFormDirty = true; //true if not same values false if the same
    //this.selectedProfile.permissions = data
  }
  savePermissions() {
    const postData: { profileId: string; permissions: any[] } = {
      profileId: this.selectedProfile.id,
      permissions: [],
    };

    postData.profileId = this.selectedProfile.id;
    // Debug.log("savePermissions",this.selectedProfile.permissions,this.AppPermissions)

    Debug.log(this.selectedProfile);
    const permissionsIds = this.selectedProfile.permissions.map(
      (permission: any) => permission.id
    );

    Debug.log(permissionsIds);

    this.userService
      .saveProfilePermissions({
        profileId: this.selectedProfile.id,
        permissionIds: permissionsIds,
      })
      .subscribe((res: IResponse) => {
        Debug.log(res);
      });

    this.isPermissionsFormDirty = false;
  }

  //------------------------------------------------------------accreditations methods

  accreditationsDisplay: any = {};
  private prepareAccreditationsForTableDisplay(input: any): any {
    // Debug.log('perparing ', input);
    let accs = input.data.map((acc: any) => {
      return {
        id: acc.id,
        user: acc.user.firstName + ' ' + acc.user.lastName,
        email: acc.user.email,
        group: acc.group.name,
        profile: acc.profile.name,
      };
    });
		accs = this.sortByIdDesc(accs);
    return {
      data: accs,
      navigation: {
        page: 0,
        totalPages: 0,
        totalRows: 0,
        itemsPerPage: 0,
      },
    };

  }
  systemPermissions = [];
  overrideUserPermissions: boolean = false;

  toggleOverridePermissions() {
    this.overrideUserPermissions = !this.overrideUserPermissions;
  }

  isAccreditationAlreadyExists(): boolean {
    return false;
  }
  showAccreditations() {}
  isAccreditationInputsVisible: boolean = false;
  saveAccreditations() {
    if (!this.selectedUser || !this.selectedGroup || !this.selectedProfile) {
      return;
    }

    const postAccreditationData = {
      userId: this.selectedUser.id,
      groupId: this.selectedGroup.id,
      profileId: this.selectedProfile.id,
      applyToSubGroups: true,
    };

    this.userService.saveAccreditations(postAccreditationData).subscribe({
      next: (saveRes: IResponse) => {
        this.AppAccreditations = saveRes.data;
        /* Swal.fire({
            icon: 'success',
            title: this.translationService.t('Accreditation created Successfully'),
            showConfirmButton: false,
            timer: 1500,
          }); */
        (this.head = 'generic.successCreateAccreditation'), (this.subHead = '');
        this.modalIcon = ICON.success;
        this.isModalOpen = true;
        setTimeout(() => {
          this.close();
        }, 2000);

        // Fetch all accreditations after saving
        this.userService.getAllAccreditations().subscribe({
          next: (getAllRes: IResponse) => {
            Debug.log('res.data', getAllRes.data);
            this.AppAccreditations = getAllRes.data;

            this.accreditationsDisplay =
              this.prepareAccreditationsForTableDisplay(getAllRes);
          },
          error: (err) => {
            console.error('Error fetching all accreditations:', err);
          },
        });
      },
      error: (err) => {
        console.error('Error saving accreditation:', err);
        /* Swal.fire({
            icon: 'error',
            title: this.translationService.t('Error saving accreditation'),
            showConfirmButton: true,
          }); */
        (this.head = 'generic.creationFailure'),
          (this.subHead = 'generic.creationAccreditationFailureMsg');
        this.modalIcon = ICON.fail;
        this.isModalOpen = true;
      },
    });
  }

  deleteAccreditation(item: any) {
    Debug.log(this.AppAccreditations.length);

    Debug.log(item.id);
    this.userService.removeAccreditation(item.id).subscribe((res: any) => {
      Debug.log(res);

      this.userService.getAllAccreditations().subscribe((res: IResponse) => {
        Debug.log('res.data', res.data);
        this.AppAccreditations = res.data;
        this.accreditationsDisplay =
          this.prepareAccreditationsForTableDisplay(res);
      });

      Debug.log(this.AppAccreditations.length);
    });
  }

  selectedProfile: any;
  updateSelectedProfile(data: any) {
    Debug.log('Profile ', data.target.value);
    this.selectedProfile = this.Profiles.data.filter(
      (profile: any) => profile.id == data.target.value
    )[0];
  }
  selectedUser: any;
  updateSelectedUser(data: any) {
    this.selectedUser = this.users.data.filter(
      (user: any) => user.id == data.target.value
    )[0];
  }
  selectedGroup: any;
  updateSelectedGroup(data: any) {
    Debug.log('Group selected', data.target.value);
    this.selectedGroup = this.groups.data.filter(
      (group: any) => group.id == data.target.value
    )[0];
    Debug.log('Group', this.selectedGroup);
  }

  close() {
    this.isModalOpen = false;
  }

	sortByIdDesc(data:any) {
		return data.sort((a:any, b:any) => b.id - a.id);
	}
}
