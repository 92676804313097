import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/utilities/auth.service';
import { UiPermissionsService } from '../services/utilities/ui-permissions.service';

@Injectable({
  providedIn: 'root',
})
  export class PermissionGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private uiPermissionService: UiPermissionsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const mapName = route.data['mapName']; // Get the map name from route data
    const user = this.authService.loadUserfromLs()
    // console.log("permissions guard",user); 
        return this.uiPermissionService.getPermissions().pipe(
          map((uiMaps) => {
            if(user.isAdmin)return true
            for (let uiMap of uiMaps) {
              if (uiMap.name === mapName) {
                const result =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    user.accreditations,
                    user.isAdmin
                  );

                if (result) {
                  return true;
                }
              }
            }
            this.router.navigate(['/home']);
            return false;
          }),
          catchError((error) => {
            console.error('Error in permission check:', error);
            this.router.navigate(['/posts']);
            return of(false);
          })
        ); 
  }
}
