export enum StatsIcon {
   VIEW,
   CLICK,
   SMILY_FACE,
   CHAT_BUBBLE,
   SHARE,
   USER_FAVORITE,
   HEART,
   BOOKMARK,
   ACTIVITY,
   STAR,
   REACTIONS,
   FBREACTIONS,
   CLICKRATE,
   ENGAGEMENT,
   ENGAGEMENTRATE,
   IMPRESSION,
   SAVED,
   INTERACTION,
   ACTIVITYPROFIL,
   NEWFANS,
   PROFILVISIT,
   LINKCLICS
  }
  