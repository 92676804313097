<div class="overflow-y-auto -mt-5 h-[90vh]">
  <div class="flex relative justify-end w-11/12 mx-auto">
    <button
      *ngIf="user?.isAdmin"
      (click)="refreshLocationData()"
      class="bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-900 p-2 fixed top-5 right-5 rounded-full border-2 border-dark_blue cursor-pointer text-sm"
    >
      <!-- {{ "pages.refreshData" | translate }} -->
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
			</svg>
			
    </button>
  </div>

  <form
    [formGroup]="pageForm"
    (ngSubmit)="onSubmit()"
    class="w-11/12 mx-auto my-0"
  >
    <fieldset
      class="relative my-10 border border-dark_blue border-4 rounded-3xl pb-14 h-[80vh]"
      style="border-radius: 3rem"
    >
      <legend class="flex bg-transparent ml-8 mb-1 relative justify-between">
        <div
          class="border-line top-1/2 absolute bg-dark_blue w-full"
          style="height: 4px"
        ></div>
        <div
          *ngIf="id"
          class="text-2xl z-10 font-semibold text-gray-700 bg-dark_blue text-white px-4 py-1 rounded-full"
          style="padding-right: 44px"
        >
          {{ "pages.editPage" | translate }}
        </div>
        <div
          *ngIf="!id"
          class="text-2xl z-10 font-semibold text-gray-700 bg-dark_blue text-white px-4 py-1 rounded-full"
          style="padding-right: 44px"
        >
          {{ "pages.createPage" | translate }}
        </div>

        <div class="flex ml-8">
          <!-- btn INFOS PRINCIPALES -->
          <div
            class="cursor-pointer text-sm z-10 font-semibold border-2 border-dark_blue px-4 py-1 ml-2 rounded-lg flex justify-center items-center"
            [ngClass]="{
						'bg-cliking_blue': creationStep == 0,
						'text-white': creationStep == 0,
						'bg-white': creationStep != 0,
						'text-text-gray-700': creationStep != 0,
					}"
            (click)="changeStep(0)"
          >
            {{ "pages.steps.mainInfos" | translate }}
          </div>

          <!-- btn INFOS DE CONTACT -->
          <div
            class="cursor-pointer text-sm z-10 font-semibold border-2 border-dark_blue px-4 py-1 ml-2 rounded-lg flex justify-center items-center"
            [ngClass]="{
						'bg-cliking_blue': creationStep == 1,
						'text-white': creationStep == 1,
						'bg-white': creationStep != 1,
						'text-text-gray-700': creationStep != 1,
					}"
            (click)="changeStep(1)"
          >
            {{ "pages.steps.contactInfos" | translate }}
          </div>

          <!-- btn HORAIRES -->
          <div
            class="cursor-pointer text-sm z-10 font-semibold border-2 border-dark_blue px-4 py-1 ml-2 rounded-lg flex justify-center items-center"
            [title]="'generic.comingSoon' | translate"
            [ngClass]="{
						'bg-cliking_blue': creationStep == 2,
						'text-white': creationStep == 2,
						'bg-white': creationStep != 2,
						'text-text-gray-700': creationStep != 2,
					}"
            (click)="changeStep(2)"
          >
            {{ "pages.steps.times" | translate }}
          </div>
          <div
            class="cursor-not-allowed text-sm z-10 font-semibold border-2 border-dark_blue px-4 py-1 ml-2 rounded-lg flex justify-center items-center"
            [title]="'generic.comingSoon' | translate"
            [ngClass]="{
						'bg-cliking_blue': creationStep == 3,
						'text-white': creationStep == 3,
						'bg-gray-300': creationStep != 3,
						'text-text-gray-700': creationStep != 3,
					}"
            (click)="changeStep(3)"
          >
            {{ "pages.steps.medias" | translate }}
          </div>
          <div
            class="cursor-not-allowed text-sm z-10 font-semibold border-2 border-dark_blue px-4 py-1 ml-2 rounded-lg flex justify-center items-center"
            [title]="'generic.comingSoon' | translate"
            [ngClass]="{
						'bg-cliking_blue': creationStep == 4,
						'text-white': creationStep == 4,
						'bg-gray-300': creationStep != 4,
						'text-text-gray-700': creationStep != 4,
					}"
            (click)="changeStep(4)"
          >
            {{ "pages.steps.products" | translate }}
          </div>
        </div>
      </legend>

      <div class="flex w-full h-full">
        <!-- INFOS PRINCIPALES -->
        <div class="step1 w-3/5 h-full" *ngIf="creationStep == 0">
          <div class="flex flex-col h-full">
            <!-- fields -->
            <!-- page name -->
            <div class="m-5">
              <fieldset
                class="border border-black border-1 rounded-full relative"
              >
                <legend class="text-sm px-1 ml-10">
                  {{ "pages.nameOfThePage" | translate }}
                </legend>
                <input
                  type="text"
                  name="title"
                  id="title"
                  formControlName="title"
                  required
                  class="px-5 py-[3px] mt-3 mb-5 mx-auto block w-11/12 text-base border border-1 border-black rounded-3xl shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder=""
                />
              </fieldset>
            </div>
            <!-- category selection -->
            <div class="mt-5 mr-5">
              <fieldset
                class="ml-5 px-1 text-sm border border-black border-1 rounded-full text-sm"
              >
                <legend class="ml-10 px-1 text-sm">
                  {{ "pages.category" | translate }}
                </legend>

                <app-categories-dropdown
                  [selectedCategory]="selectedCategory"
                  (selectedCategoryChange)="selectedCategory = $event"
                  [SocialMediaId]="id"
                  [tailwindCss]="
                    'flex justify-content-between w-11/12 mx-auto  mt-3 mb-6 pl-8 pr text-sm border c-border-blue rounded-full p-1 py-1 focus:outline-none focus:ring-blue-500 focus:c-border-blue c-text-blue bg-white'
                  "
                >
                </app-categories-dropdown>
              </fieldset>
            </div>
            <!-- description -->
            <div class="m-5 mb-0 flex-grow relative">
              <fieldset
                class="border border-black border-1 relative"
                style="border-radius: 2.5rem"
                [ngClass]="{ 'border-red-500': description.value.length > 750 }"
              >
                <legend class="text-sm px-1 ml-10">
                  {{ "pages.description" | translate }}
                </legend>
                <textarea
                  (input)="resizeDescriptionInput()"
                  #description
                  type="text"
                  name="description"
                  id="description"
                  formControlName="description"
                  class="px-5 py-[3px] mt-3 mb-5 mx-auto block w-11/12 text-base border border-1 rounded-3xl shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 overflow-auto resize-none"
                  placeholder=""
                  style="min-height: 100px; max-height: 100%"
                  [ngClass]="{
                    'border-red-500': description.value.length > 750
                  }"
                ></textarea>
                <div
                  [ngClass]="{ 'text-red-500': description.value.length > 750 }"
                  class="text-red-500 text-sm absolute top-0 right-0 transform -translate-y-full translate-x-[-30%] bg-indigo-100 px-2"
                >
                  {{ description.value.length }}/750 characters
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- INFOS DE CONTACT -->
        <div class="step2 w-3/5 h-full" *ngIf="creationStep == 1">
          <div class="flex flex-col">
            <!-- fields -->
            <!-- page phone number -->
            <div class="m-5">
              <fieldset
                class="border border-black border-1 rounded-full relative"
              >
                <legend class="text-sm px-1 ml-10">
                  {{ "pages.phone" | translate }}
                </legend>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  formControlName="phone"
                  required
                  class="px-5 py-[3px] mt-3 mb-5 mx-auto block w-11/12 text-base border border-1 border-black rounded-3xl shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder=""
                />
              </fieldset>
            </div>
            <!-- page website -->
            <div class="m-5">
              <fieldset
                class="border border-black border-1 rounded-full relative"
              >
                <legend class="text-sm px-1 ml-10">
                  {{ "pages.webSite" | translate }}
                </legend>
                <input
                  type="text"
                  name="webSite"
                  id="webSite"
                  formControlName="webSite"
                  required
                  class="px-5 py-[3px] mt-3 mb-5 mx-auto block w-11/12 text-base border border-1 border-black rounded-3xl shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder=""
                />
              </fieldset>
            </div>
            <!-- address -->
            <div class="m-5">
              <fieldset
                class="border border-black border-1 rounded-full relative"
              >
                <legend class="text-sm px-1 ml-10">
                  {{ "pages.address" | translate }}
                </legend>
                <app-address-autocomplete
                  [pageForm]="pageForm"
                  [currentAddress]="page ? page.storefrontAddress : null"
                  (addressSelected)="onAddressSelected($event)"
                ></app-address-autocomplete>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- HORAIRES -->
        <div class="step3 w-3/5 h-full" *ngIf="creationStep == 2">
          <app-hours-form
            [regularHours]="page.regularHours"
            [specialHours]="page.specialHours"
            [moreHours]="page.moreHours"
            [moreHoursTypes]="page.categories?.primaryCategory?.moreHoursTypes"
            [openInfo]="page.openInfo"
          >
          </app-hours-form>
        </div>

        <div 
					*ngIf="!multiEdit" 
					class="w-2/5 pr-5 h-full"
					[ngClass]="{'pb-7': pageIds}"
				>
          <!-- page preview -->
					<div *ngIf="pageIds" class="flex justify-center relative bg-white rounded-full border border-cliking_blue mt-2">
						<!-- Notification Badge -->
						<span class=" text-xl text-cliking_blue py-1 px-2 relative">
							{{ pageIds.length == 1 ? pageIds.length  + " " + ("generic.selectedPage" | translate) : pageIds.length  + " " + ("generic.selectedPages" | translate)}} 
							<!-- Floating "X" Icon -->
							<!-- <span 
								class="absolute -top-2 -right-2 bg-cliking_blue text-white rounded-full w-5 h-5 flex items-center justify-center cursor-pointer shadow-md"
								(click)="clearMultiSelect()">
								×
							</span> -->
						</span>
					</div>
          <app-google-page-preview
            [medias]="page ? page.medias : []"
            [title]="pageForm.value.title"
            [primaryCategory]="
              selectedCategory ? selectedCategory.displayName : ''
            "
            [address]="pageForm.value.address"
            [phoneNumber]="pageForm.value.phoneNumber"
            [description]="pageForm.value.description"
            [horaires]="
              page
                ? {
                    regularHours: page.regularHours,
                    specialHours: page.specialHours
                  }
                : {}
            "
          >
          </app-google-page-preview>
        </div>

        <div
          *ngIf="multiEdit"
          class="w-2/6 pr-5 h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 overflow-auto"
        >
          <!-- Multi pages preview -->
          <div
            *ngFor="let page of pages; let i = index"
            class="w-60 h-64 border border-gray-400 rounded-2xl overflow-hidden relative hover:cursor-pointer"
          >
            <div class="h-1/2">
              <img
                [src]="page.medias[0] || 'assets/img/nomedia1.png'"
                alt="logo"
                class="h-full w-full flex-grow object-cover"
              />
            </div>
            <div
              class="h-1/2 p-4 border-t border-gray-400 flex flex-col justify-between bg-white"
            >
              <span class="font-medium text-lg">{{ page.title }}</span>
              <!-- Title -->
              <span
                class="font-light text-xs truncate whitespace-nowrap overflow-hidden block w-48"
                >{{ page.categories.primaryCategory.displayName }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- steps navigation buttons -->
      <div class="absolute w-full bottom-0 translate-y-1/3 px-5">
        <div class="flex justify-between mt-5 p-3 pb-0">
          <button
            type="button"
            (click)="prevStep()"
            [class.opacity-0]="creationStep == 0"
            [class.cursor-auto]="creationStep == 0"
            class="flex-grow-0 px-4 ml-20 py-1 c-bg-blue text-white font-normal rounded-full hover:c-bg-blue focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-7 border-[3px] border-dark_blue"
          >
            ← {{ "posts.previousStep" | translate }}
          </button>

          <button
            type="submit"
            [class.opacity-1]
            class="flex-grow-0 px-4 py-1 bg-cliking_blue text-white font-normal rounded-full hover:c-bg-blue focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-7 border border-[3px] border-dark_blue"
          >
            {{ "pages.save" | translate }}
          </button>

          <div class="relative flex flex-col items-center" #container>
            <button
              #button
              type="button"
              [disabled]="false"
              [class.cursor-not-allowed]="creationStep == 2"
              (click)="nextStep()"
              class="px-4 py-1 c-bg-blue text-white font-normal rounded-full border border-[3px] border-dark_blue focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-10"
            >
              {{
                creationStep !== 3
                  ? ("posts.nextStep" | translate) + " →"
                  : ("posts.finish" | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>

  <script>
    document.addEventListener("DOMContentLoaded", function () {
      const textarea = document.getElementById("description");

      textarea.addEventListener("input", function () {
        Debug.log("input");
        // Reset textarea height to auto to calculate new height
        this.style.height = "auto";

        // Calculate the new height
        const newHeight = this.scrollHeight;

        // Set the new height, but not exceeding the max height
        this.style.height =
          Math.min(newHeight, this.parentElement.clientHeight * 0.5) + "px";

        // Add overflow if the content exceeds the max height
        if (newHeight > this.parentElement.clientHeight * 0.5) {
          this.style.overflowY = "auto";
        } else {
          this.style.overflowY = "hidden";
        }
      });
    });
  </script>
</div>
