<div class="w-[100%] relative overflow-auto">

	<div 
		class=" mx-auto  flex flex-col h-[85vh]" 
		[ngClass]="{
			'w-11/12 ': !dashboard,
		}"
	>
	<!-- 'h-[85vh] ': !dashboard,
	'h-full': dashboard, -->
		<div *ngIf="false && userHasPermission('publication.get.:id') && (!dashboardSource || dashboardSource == 'facebook' || dashboardSource == 'instagram')" class="flex justify-center gap-2  text-sm m-0 p-1">
			<div 
				*ngIf="(!dashboardSource || dashboardSource == 'facebook')"
				class="p-2 bg-facebook text-white rounded-full  flex gap-1"
				(click)="fetchPosts('facebook')"
				[ngClass]="{
					'cursor-not-allowed': isFetchingForFacebookPosts,
					'cursor-pointer': !isFetchingForFacebookPosts
				}"
			>
				<div>
					<span [ngClass]="{'cursor-not-allowed loading loading-spinner': isFetchingForFacebookPosts}"></span>
				</div>
				{{"pages.fetchFacebookPosts" | translate}}
			</div>
			<div 
				*ngIf="(!dashboardSource || dashboardSource == 'instagram')"
				class="p-2 bg-pink-600 text-white rounded-full flex gap-1"	
				(click)="fetchPosts('instagram')"
				[ngClass]="{
					'cursor-not-allowed': isFetchingForInstagramPosts,
					'cursor-pointer': !isFetchingForInstagramPosts
				}"
			>
				<div>
					<span [ngClass]="{'loading loading-spinner': isFetchingForInstagramPosts}"></span>
				</div>
				{{"pages.fetchInstagramPosts" | translate}}
			</div>
		</div>

		<!-- filters -->
		<div 
			class=" z-40  pb-5 bg-indigo-100"
			[ngClass]="{
				'pr-4': !dashboard,
			}"
		>
			<app-post-filters [dashboard]="dashboard" [isRouteOnDashboard]="isRouteOnDashboard()" [dashboardSource]="dashboardSource || ''"/>
		</div>
	
		<!-- cards grid -->
		<div class="flex-1 overflow-auto pb-28 pr-3" (scroll)="infinitScroll($event)">
			<div class="relative ">
				<div class="grid mb-3 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-5 ">
					<ng-container *ngFor="let post of posts; index as i"  >
						<div *ngIf="checkVisibility(post)">
							<app-post-preview 
								[user]="user" 
								[index]="i" 
								[displayAs]="'post'"
								[postIndex]="i"
								[post]="post" (onDeleteClicked)="confirmDeleteModalVisible = true; currentPost = post"
								[isDeletionInProgress]="isDeletionInProgress(post.id)"
							/>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<app-modal 
		[isOpen]="confirmDeleteModalVisible" 
		[icon]="modalIcon" 
		[heading]="modalHeading"
		[subHeading]="modalSubHeading"
		[socialMediaInfos]="extractSocialMediaInfo(currentPost)"
		(closeModal)="confirmDeleteModalVisible=false" 
		(deleteItem)="confirmPostRemoval($event)"
	></app-modal>

	<app-loader [isLoading]="isLoading"/>

</div>
