import {  Component,ElementRef,EventEmitter,Input,Output,SimpleChanges,ViewChild,} from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Debug } from 'src/app/utils/debug';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss'],
})
export class PostPreviewComponent extends BaseComponent {
  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
    protected authService: AuthService
  ) {
    super(translationService);
  }
  @Input() post: any;
  @Input() postIndex!: number;
  @Input() displayAs!: string;
  @Input() isPostFilter!: boolean;
  @Input() isDraftFilter!: boolean;
  @Input() isPlannedFilter!: boolean;
  @Input() index!: number;
	@Input() isDeletionInProgress: boolean = false;
  apiUrl = environment.apiUrl;
  @Input() user: any;
	env: string = environment.env;
  isGroupNamesToolTip:boolean = false;
  showDateToolTip:boolean = false;
  mainMedia: any = null;
  postImagesCount: number = 0
  postUniquePlatforms:string[]=[]
  ngOnChanges(changes: SimpleChanges){
    if(changes['post']){
     this.postUniquePlatforms = ([...new Set(this.post.SocialPlatforms.map((sm:any) => sm.source ))]) as string[]

      // console.log("postSocialMedia", this.postUniquePlatforms)
      for (const platform of this.post.SocialPlatforms){
        // console.log('medias',platform.medias)
        if(platform.medias.length>1){
          this.postImagesCount = platform.medias.length
        }
      }      
    }
  }
  
  ngOnInit(): void {
    //  this.postUniquePlatforms = ([...new Set(this.post.SocialPlatforms.map((sm:any) => sm.source ))]) as string[]

    //count the number of media used
    this.SetDisplayAs();
    if (this.post.title == undefined || this.post.title == '') {
      this.post.title = 'N/A';
    }
    
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;

      //when platform filter si selected with chek if a preview platforms are all hidden so we can hide it 
      const platforms=this.post.SocialPlatforms.map((sm:any)=> sm.source)
      this.platformFilterMapping = {
        facebook: this.currentPlaTformsFilters.isFacebookON,
        google: this.currentPlaTformsFilters.isGoogleON,
        linkedin: this.currentPlaTformsFilters.isLinkedinON,
        instagram: this.currentPlaTformsFilters.isInstagramON,
      };
      // Check if all platforms in the list are off
      const allOff = platforms.every((platform:string) => !this.platformFilterMapping[platform]);
      this._canShowPreview = !allOff 
     // console.log("allOff",allOff,platforms);


      this.isPostPlatformSelected();
    });

    this.uiStateService.postTypesUpdated$.subscribe(data => {
      this.isPostFilter = data.isPosted
      this.isDraftFilter = data.isDraft
      this.isPlannedFilter = data.isPlanned
    })
    
    this.setMainMedia();
    this.postAnalytics();    
	}

  SetDisplayAs() {
    if (
      this.post.publishingDatetime == null &&
      this.post.expectedPublishingDatetime == null
    ) {
      this.displayAs = 'draft';
    } else if (new Date(this.post.expectedPublishingDatetime) > new Date()) {
      this.displayAs = 'planned';
    } else {
      this.displayAs = 'post';
    }
  }

  @Output() onDeleteClicked: EventEmitter<any> = new EventEmitter();
  emitOpenModal() {
		// document.body.click();
    this.onDeleteClicked.emit();
  }

  private observeContentChanges(): void {
   
  }

  currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

	platformFilterMapping: any = {
    facebook: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  // isPublished: boolean = true;
   isPlanned: boolean = true;
  // isDraft: boolean = true;

  activeGroups:string[] = [];
  getPostGroupsNamesList() {
    for (let socialMedia of this.post.SocialPlatforms) {
      if(!this.activeGroups.includes(socialMedia.group.name)){
        this.activeGroups.push(socialMedia.group.name);
      }
    }
    return this.activeGroups.length==1?this.activeGroups[0]:this.activeGroups[0]+", ..."
  }

  getGroupLogoUrl(){
    let groupsLogos = []
    for (let socialMedia of this.post.SocialPlatforms) {
      // console.log(socialMedia.group.name,socialMedia.group.logoUrl)
      groupsLogos.push(socialMedia.group.logoUrl)
    }
    groupsLogos = [...new Set(groupsLogos)];
    // console.log("logos",groupsLogos)
    return groupsLogos.length==1 && groupsLogos[0].includes('https://aws-cliking-social-media.s3.eu-west-3')? groupsLogos[0]:this.apiUrl + '/assets/company-icon.png'
  }

  setMainMedia(): void {
    if (this.post.SocialPlatforms?.length > 0) {
      for (const socialMedia of this.post.SocialPlatforms) {
        if (socialMedia.medias?.length > 0) {
          this.mainMedia = socialMedia.medias[0];
          break;
        }
      }
    }
  }

  mediaIsVideo(media: any): boolean {
    return media?.mimeType?.startsWith('video') ?? false;
  }

  formatPostStats(num: number) {
    if (Math.abs(num) < 1000) {
      return num.toString();
    } else if (Math.abs(num) >= 1000 && Math.abs(num) < 1000000) {
      return (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k';
    } else {
      return (Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(1) + 'm';
    }
  }


  filterValue(){
    return this.isPostFilter && this.displayAs === 'post' ||  this.isDraftFilter && this.displayAs === 'draft'|| this.isPlannedFilter && this.displayAs === 'planned'
  }

  useAsNewPostTemplate(action:string) {
   // const mediaQuery = `/posts?action=${action}&ref=${this.post.id}`;

    const postJsonStr = JSON.stringify(this.post);
    const mediaQuery = `/posts?useAs=${action}&postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }



  private _canShowPreview: boolean = true;

  // Use a setter for the canShowPreview property
  set canShowPreview(value: boolean) {

    if(this.displayAs == 'draft' && this.isPlanned) 
    this._canShowPreview = value;
  }

  // // Use a getter to maintain accessibility to the canShowPreview value
  get canShowPreview(): boolean {
    return this._canShowPreview;
  }

  isPostPlatformSelected(): boolean {
    let showfacebook =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'facebook' && this.currentPlaTformsFilters.isFacebookON;
      }).length > 0;
    let showlinkedin =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON;
      }).length > 0;
    let showgoogle =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'google' && this.currentPlaTformsFilters.isGoogleON;
      }).length > 0;
    let showInstagram =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'instagram' && this.currentPlaTformsFilters.isInstagramON;
      }).length > 0;
    this.canShowPreview = this.areAllPostPlatformsFiltred();

    return showfacebook || showgoogle || showlinkedin || showInstagram;
  }

  areAllPostPlatformsFiltred(): boolean {
    let allSelected: boolean[] = [];
    let currentPostPlatforms = this.post.SocialPlatforms;
    currentPostPlatforms.forEach((platform: any) => {
      switch (platform.source) {
        case 'facebook':
          allSelected.push(this.currentPlaTformsFilters.isFacebookON);
          break;
        case 'google':
          allSelected.push(this.currentPlaTformsFilters.isGoogleON);
          break;
        case 'linkedin':
          allSelected.push(this.currentPlaTformsFilters.isLinkedinON);
          break;
        case 'instagram':
          allSelected.push(this.currentPlaTformsFilters.isInstagramON);
          break;
      }
    });

    const allFalse = allSelected.every((value) => value === false);
    return !allFalse;
  }

  ngOnDestroy(): void {
   
  }

  postDetails() {
  //  const postJsonStr = JSON.stringify(this.post);
    const postId = this.post.id
    const mediaQuery = `/library/post-details/${postId}`;
		window.location.href = mediaQuery;
  }

  postAnalytics() {
    this.post.views = 0;
    this.post.likeCount = 0;
    this.post.comments = 0;
    this.post.shares = 0;
    this.post.SocialPlatforms.forEach((item: any) => {
      if (item.source == 'linkedin') {
        this.post.likeCount += this.gVal(item?.postAnalytics?.likeCount);
        this.post.views += this.gVal(item?.postAnalytics?.clickCount);
        this.post.comments += this.gVal(item?.postAnalytics?.commentCount);
        this.post.shares += this.gVal(item?.postAnalytics?.shareCount);
      }
      if (item.source == 'facebook') {
        this.post.likeCount += this.gVal(item?.postAnalytics?.reactions);
        this.post.views += this.gVal(item?.postAnalytics?.metrics[0].value);
        this.post.comments += this.gVal(item?.postAnalytics?.comments);
        this.post.shares += this.gVal(item?.postAnalytics?.shares);
      }
      if (item.source == 'instagram') {
				try {
					this.post.likeCount += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "likes"));
					this.post.views += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "impressions"));
					this.post.comments += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "comments"));
					this.post.shares += this.gVal(item?.postAnalytics?.find((insight:any)=>insight.name == "shares"));
				} catch(error) {
					console.log("erreur instagram insights")
				}
      }
    });
  }

  gVal(data: any) {
    return data && Number(data) ? Number(data) : 0;
  }




	userHasPermission(permission: string): boolean {
		return (
			this.user?.isAdmin || 
			this.user?.accreditations[0]?.profile?.permissions.some((profilePermission: any) => profilePermission.action == permission)
		)
	}

	handleClick(event: MouseEvent, id: number): void {
		if (this.isDeletionInProgress) {
			event.preventDefault();
			return
		}

		if (this.displayAs === 'post') {
			// No action needed, allow the default behavior -> link
			return;
		} else if (this.displayAs === 'draft') {
			event.preventDefault();
			this.useAsNewPostTemplate('draft');
		} else if (this.displayAs === 'planned') {
			event.preventDefault();
		}
	}

  postToolTipDatePrefix(){
    switch(this.displayAs) {
      case 'post':return 'generic.publishedOn'
      case 'planned':return 'generic.plannedOn'
      case 'draft':return 'generic.CreatedOn'
    }
    return "invalid displayAs"
  }
}
