import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ICON } from "src/app/interfaces/ModalIcon"


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() isOpen: boolean = false;
  @Input() isAutoClosed: boolean = false;
  @Input() autoClosedDuration: number = 1500;
  @Input() heading: string = "";
  @Input() subHeading: string = "";
  @Input() icon: ICON = ICON.nothing;
  @Input() showCloseBtn: boolean = false;
  @Input() hideElements: boolean = false;
  @Input() showLoadingDots: boolean = false;

  @Input() socialMediaInfos: { id: number; name: string; source: string; postUrl: string }[] | null = null;
	selectedSocialMedias: any[] = [];
	allSelected: boolean = false;
	step: number = 0;

  @Output() closeModal = new EventEmitter<void>();
  @Output() deleteItem = new EventEmitter<{ selected: { name: string; source: string }[] } | void>();

	constructor(private cdr: ChangeDetectorRef) {}

  close() {
    this.closeModal.emit();
  }

  isIconProvided(){
    return this.icon != ICON.nothing
  }

  isIconQuestion(){
    return this.icon == ICON.question
  }

  isIconWarning(){
    return this.icon == ICON.warning
  }

  isIconInfo(){
    return this.icon == ICON.info
  }

  isIconDelete(){
    return this.icon == ICON.delete
  }

  isIconSuccess(){
    return this.icon == ICON.success
  }

  isIconFail(){
    return this.icon == ICON.fail
  }

	delete() {
		if (this.socialMediaInfos) {
			// Add any Instagram social medias to the selectedSocialMedias
			const instagramSocialMedias = this.socialMediaInfos
				?.filter((info) => info.source === 'instagram')
				.map((info) => info.id) || []; // Extract the `id` of each Instagram social media
	
			this.socialMediaInfos = null; // removes the front

			this.selectedSocialMedias = [
				...(this.selectedSocialMedias || []), // Ensure `this.selectedSocialMedias` is an array
				...instagramSocialMedias,
			];
	
			this.deleteItem.emit({ selected: this.selectedSocialMedias });
		} else {
			this.deleteItem.emit();
		}
	}

  ngOnInit() {
    if(this.isAutoClosed){
      setTimeout(() => {
        this.close()
      }, this.autoClosedDuration)
    }
  }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['isOpen'] && changes['isOpen'].currentValue === true) {
			if (this.socialMediaInfos) {
				// Reset when modal is opened
				if ((this.socialMediaInfos?.length || 0) > 1 || (this.socialMediaInfos?.length == 1 && this.hasInstagram())) { // more than one plateform
				this.heading = 'generic.confirmPostDeletionStep1'; 
				this.subHeading = 'generic.confirmPostDeletionStep1Msg';
				this.valuesReset();
			} else {
				this.step = 1;
				this.heading = 'generic.confirmDeletion'; 
				this.subHeading = 'generic.confirmPostDeletionMsg';
				this.selectedSocialMedias = this.socialMediaInfos?.map((info) => info.id) || [];
				console.log("selectedSocialMedias", this.selectedSocialMedias)
			}
			}
		}
	}

	platformIsSelected(id: any): boolean {
		return this.selectedSocialMedias.some(selectedId => selectedId === id);
	}
	
	hasInstagram(){
		return this.socialMediaInfos?.some((e) => e.source == "instagram");
	}

	toggleSelection(id: any) {

		this.selectedSocialMedias.includes(id)
			? this.selectedSocialMedias = this.selectedSocialMedias.filter(selectedId => selectedId !== id)
			: this.selectedSocialMedias.push(id);

		// Update `allSelected` based on selection status
		this.allSelected = this.socialMediaInfos?.length === this.selectedSocialMedias.length;
	}


	toggleAll() {
  if (this.allSelected) {
    this.valuesReset();
  } else {
    // Select all social medias except those with the source 'instagram'
    this.selectedSocialMedias = this.socialMediaInfos
      ?.filter((info) => info.source !== 'instagram') // Exclude Instagram
      .map((info) => info.id) || [];
    this.allSelected = this.selectedSocialMedias.length > 0; // is 0 is the post onmy has instgram pages
  }
}

	trackByPlatformId(index: number, platform: { id: number }): number {
		return platform.id; // or any unique property
	}

	nextStep(){
		if (this.selectedSocialMedias.length > 0) {
			this.step += 1;
			if (this.step == 1)
				this.setHeadingsStep2()
		}
	}

	previousStep(){
		if (this.selectedSocialMedias.length > 0) {
			this.step -= 1;
			if (this.step == 0)
				this.setHeadingsStep1()
		}
	}

	setHeadingsStep1(){
		this.heading = 'generic.confirmPostDeletionStep1'; 
		this.subHeading = 'generic.confirmPostDeletionStep1Msg';
	}

	setHeadingsStep2(){
		this.heading = 'generic.confirmDeletion'; 
		this.subHeading = 'generic.confirmPostDeletionMsg';
	}

	private valuesReset() {
		this.selectedSocialMedias = [];
		this.allSelected = false;
		this.step = 0;
		this.hideElements = false
		this.showLoadingDots = false
	}

}
