import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import * as moment from 'moment';

@Component({
  selector: 'app-post-create-preview',
  templateUrl: './post-create-preview.component.html',
  styleUrls: ['./post-create-preview.component.scss']
})
export class PostCreatePreviewComponent implements OnChanges {
  @Input() selectedPlateform: string = "";
  @Input() data: any;
  @Input() screenHeight: number = 0;
  @Input() screenWidth: number = 0;
  @Input() copyPost: boolean = true;
  @Input() postIsCreated: boolean = false;

  linkedinData: any = null;
  linkedinMedias: any[] = [];
  googleData: any = null;
  googleMedias: any[] = [];
  facebookData: any = null;
  facebookMedias: any[] = [];
  instagramData: any = null;
  instagramMedias: any[] = [];
  mediaAllpreview: any = null;

  showFullIgText: boolean = false;
  showFullGoogleText: boolean = false;
	formattedText: SafeHtml | null = null;


  constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(){
		this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.initData();
    }
  }

	initData(){
		// Ensure data is processed when available
		// console.log("this.data", this.data)
		this.data.socialMedia?.forEach((item: { medias: string[]; source: string; }) => {
			if (item.source === "linkedin") {
				this.linkedinData = item;
			}
			if (item.source === "google") {
				this.googleData = item;
			}
			if (item.source === "facebook") {
				this.facebookData = item;
			}
			if (item.source === "instagram") {
				this.instagramData = item;
			}
		});
		

		// Assign media lists
		this.linkedinMedias = this.data.MediaList || [];
		this.facebookMedias = this.data.MediaList || [];
		this.instagramMedias = this.data.MediaList || [];
		this.googleMedias = this.data?.mediaGoogle ? [{ url: this.data.mediaGoogle }] : [];

		this.formattedText = this.parseLinks(this.data?.text);


		this.choiceAllMediaPreview()
	}

	choiceAllMediaPreview(){
    if(this.googleMedias.length > 0){
      this.mediaAllpreview = this.googleMedias[0];
      return
    }
    
    if(this.linkedinMedias.length > 0){
      this.mediaAllpreview = this.linkedinMedias[0];
      return
    }
    
    if(this.facebookMedias.length > 0){
      this.mediaAllpreview = this.facebookMedias[0];
      return
    }
    
    if(this.instagramMedias.length > 0){
      this.mediaAllpreview = this.instagramMedias[0];
      return
    }
  }

	

  formatDateWithMoment(dateString: string): string {
    if (dateString != null) {
      return moment(dateString).format('D MMMM YYYY');
    } else {
      return '';
    }
  }

  mediaIsVideo(medias: any[]): boolean {
    return (medias.length === 1 && medias[0] != null && medias[0]?.type?.startsWith("video/"));
  }

  displayFullIgText(): void {
    this.showFullIgText = !this.showFullIgText;
  }

  // Instagram-specific functionality
  igActiveIndex: number = 0;

  prev(): void {
    this.igActiveIndex =
      (this.igActiveIndex - 1 + this.instagramMedias.length) %
      this.instagramMedias.length;
  }

  next(): void {
    this.igActiveIndex =
      (this.igActiveIndex + 1) % this.instagramMedias.length;
  }

  // Google-specific functionality
  displayFullGoogleText(): void {
    this.showFullGoogleText = !this.showFullGoogleText;
  }

  capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

	// FB-specific functionality

	timeSince(dateString: string): string {
    console.log('this.data',this.data);
    
		const date = new Date(dateString);
		const now = new Date();
		const diffInMs = now.getTime() - date.getTime();
		const diffInMinutes = Math.floor(diffInMs / 60000); // Convert milliseconds to minutes
		const diffInHours = Math.floor(diffInMinutes / 60);
	
		if (diffInMinutes < 60) {
			return `${diffInMinutes} min`;
		} else if (diffInHours < 24) {
			return `${diffInHours} h`;
		} else {
			// Format date in "5 novembre 2024, 11h41"
			const options: Intl.DateTimeFormatOptions = {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false,
			};
			return date.toLocaleDateString('fr-FR', options).replace(',', ' à');
		}
	}

	parseLinks(text: string): SafeHtml {
    if (!text) return text;

    // Regex to match URLs
    const urlRegex = /\b((https?:\/\/|www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))\b/g;

    // Replace URLs with styled spans
    const processedText = text.replace(urlRegex, (match) => {
      const url = match.startsWith('http') ? match : `https://${match}`;
      return `<a href="${url}" target="_blank" style="color: #0064d1; text-decoration: none;">${match}</a>`;
    });

    // Sanitize the HTML to prevent XSS
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
}