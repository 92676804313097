<div class="w-64 bg-white text-black text-[0.9vw] rounded-2xl flex items-center justify-start relative z-10 shadow" [style.width.px]="screenWidth<=1400 && (downLeft || downRight) ? screenWidth*0.14 : screenWidth*0.175" >
    <div *ngIf="downRight || downLeft" class="absolute top-[-8px] z-20" [ngClass]="{'left-[72.5%] transform -translate-x-1/2': downRight,'left-1/4 transform -translate-x-1/2':downLeft}"> 
        <div class="w-0 h-00" style="width: 0; height: 0; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid #ffffff; box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.20);"></div>
    </div>
    <div *ngIf="left" class="absolute top-1/2 right-[-20px] transform -translate-y-1/2 -translate-x-[12.5px] z-20"> 
        <div class="w-0 h-00" style="border-top: 8px solid transparent; border-bottom: 8px solid transparent; border-left: 8px solid #ffffff; box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.20);"></div>
    </div>
    <div *ngIf="right" class="absolute top-1/2 left-[-3px] transform -translate-x-1/2 -translate-y-[12.5px] z-20">
        <div class="w-0 h-0" style="border-top: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid #ffffff; box-shadow: 4px 4px 100px rgba(0, 0, 0, 0.20);"></div>
    </div>
    
    <span class="m-2 text-left z-10">{{tooltipText|translate}}</span> 
</div>
