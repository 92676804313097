import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatsIcon } from 'src/app/enums/statsSVGIcon';

@Component({
  selector: 'app-post-stats-widgets',
  templateUrl: './post-stats-widgets.component.html',
  styleUrls: ['./post-stats-widgets.component.scss']
})
export class PostStatsWidgetsComponent {
  @Output() dataEvent: EventEmitter<boolean> = new EventEmitter();

  @Input() value: string = "0";
  @Input() widgetLabel: string = "";
  @Input() icon:StatsIcon=StatsIcon.CLICK;
  @Input() plateform: string = '';
  @Input() screenHeight: number = 0;
  @Input() home: boolean = false;
  @Input() tooltipText: string = '';
  @Input() reactions: any = {};
  @Input() right: boolean = false;
  @Input() left: boolean = false;
  @Input() downLeft: boolean = false;
  @Input() downRight: boolean = false;
  facebookReactionView: boolean = false;
  isHovered: boolean = false;

  

  ngOnInit(){
    
  }

  onHover(state: boolean): void {
    this.isHovered = state;
  }

  isIconClick(){
    return this.icon === StatsIcon.CLICK 
  }
  isIconClickRate(){
    return this.icon === StatsIcon.CLICKRATE
  }
  isIconShare(){
    return this.icon === StatsIcon.SHARE 
  }
  isIconSmilyFace(){
    return this.icon === StatsIcon.SMILY_FACE 
  }
  isIconChatBubble(){
    return this.icon === StatsIcon.CHAT_BUBBLE 
  }
  isIconView(){
    return this.icon === StatsIcon.VIEW 
  }
  isIconImpression(){
    return this.icon === StatsIcon.IMPRESSION 
  }
  isIconUserFevorite(){
    return  this.icon === StatsIcon.USER_FAVORITE
  }
  isIconActivity(){
    return this.icon === StatsIcon.ACTIVITY
  }
  isIconHeart(){
    return this.icon === StatsIcon.HEART
  }
  isIconBookMark(){
    return this.icon === StatsIcon.BOOKMARK
  }
  isIconStar(){
    return this.icon === StatsIcon.STAR
  }
  isReaction(){
    return this.icon === StatsIcon.REACTIONS
  }
  isFbReaction(){
    return this.icon === StatsIcon.FBREACTIONS
  }
  isEngagement(){
    return this.icon === StatsIcon.ENGAGEMENT
  }
  isEngagementRate(){
    return this.icon === StatsIcon.ENGAGEMENTRATE
  }
  isSaved(){
    return this.icon === StatsIcon.SAVED
  }
  isInteraction(){
    return this.icon === StatsIcon.INTERACTION
  }
  isActivityProfil(){
    return this.icon === StatsIcon.ACTIVITYPROFIL
  }
  isNewFans(){
    return this.icon === StatsIcon.NEWFANS
  }
  isProfilVisit(){
    return this.icon === StatsIcon.PROFILVISIT
  }
  isLinkClics(){
    return this.icon === StatsIcon.LINKCLICS
  }

  reactionView(){
    this.facebookReactionView = !this.facebookReactionView;
    this.dataEvent.emit(this.facebookReactionView);
  }

  formatNumber(value: number): string {
    if(!value)
      return '0'
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'm'; // Pour les millions
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k'; // Pour les milliers
    } else {
      return value.toString(); // Moins de 1000
    }
  }
}
