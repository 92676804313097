<form [formGroup]="newUserForm">
  <fieldset class="m-5 border-[3px] border-cliking_blue rounded-3xl">
    <legend class="text-sm px-5 py-1 rounded-3xl bg-cliking_blue text-white">
      Enter New User Details
    </legend>
    <div class="join m-5">

      <div class="form-control">
        <label class="label cursor-pointer">
          <input type="checkbox"  class="checkbox checkbox-warning"  formControlName="isAdmin"/>
          <span class="label-text ml-5">is Super Admin (has all permissions on the app) ?</span> 
        </label>
      </div>
    </div>
    <div class="join m-5">
      <input
        class="input input-bordered input-sm mx-[3px]"
        type="text"
        placeholder="first name"
        formControlName="firstName"
        [ngClass]="{
          'input-error':
            newUserForm.get('firstName')?.invalid &&
            newUserForm.get('firstName')?.touched,
          'input-accent': newUserForm.get('firstname')?.valid
        }"
      />
      <input
        class="input input-bordered input-sm mx-[3px]"
        type="text"
        placeholder="last name"
        formControlName="lastName"
        [ngClass]="{
          'input-error':
            newUserForm.get('lastName')?.invalid &&
            newUserForm.get('lastName')?.touched,
          'input-accent': newUserForm.get('lastName')?.valid
        }"
      />
      <input
        class="input input-bordered input-sm mx-[3px]"
        placeholder="email"
        formControlName="email"
        [ngClass]="{
          'input-error':
            newUserForm.get('email')?.invalid &&
            newUserForm.get('email')?.touched,
          'input-accent': newUserForm.get('email')?.valid
        }"
      />
      <input
        class="input input-bordered input-sm mx-[3px]"
        placeholder="password"
        formControlName="password"
        [ngClass]="{
          'input-error':
            (newUserForm.get('password')?.invalid &&
              newUserForm.get('password')?.touched) ||
            (newUserForm.get('password')?.touched && newUserForm.get('confirmPassword')?.touched &&
              newUserForm.get('password')?.value !==newUserForm.get('confirmPassword')?.value),
          'input-accent':
            newUserForm.get('password')?.valid &&
            newUserForm.get('password')?.value ===
            newUserForm.get('confirmPassword')?.value
        }"
      />
      <input
        class="input input-bordered input-sm mx-[3px]"
        placeholder="confirm password"
        formControlName="confirmPassword"
        [ngClass]="{
        'input-error': newUserForm.get('confirmPassword')?.errors?.['passwordMismatch'] && newUserForm.get('confirmPassword')?.touched,
        'input-accent': newUserForm.get('confirmPassword')?.touched && newUserForm.get('confirmPassword')?.valid && !newUserForm.get('confirmPassword')?.errors?.['passwordMismatch']
      }"
      />
      <button
        type="submit"
        (click)="this.createNewUser();"
        [disabled]="!newUserForm.valid"
        class="btn btn-accent btn-circle btn-sm text-white mx-1"
      >
        +
      </button>
    </div>
  </fieldset>
</form>
<app-modal 
  [isOpen]="isModalOpen"
  [heading]="headingMsg"
  [subHeading]='subHeadingMsg' 
  (closeModal)="close()" 
  [icon]="modalIcon"
  >
</app-modal>

<app-table
  [skipColumns]="['accreditations', 'userPermissions', 'isAdmin']"
  [readOnlyKeys]="['email']"
  [entity]="'User'"
  [res]="users"
  [isWriteEnabled]="true"
  [isAdmin]="user ? user.isAdmin : null"
  (editItem)="editUser($event)"
  (deleteItem)="deleteUser($event)"
  (firstClicked)="getUsersFirstPage()"
  (previoustClicked)="getUsersPreviousPage()"
  (nextClicked)="getUsersNextPage()"
  (lastClicked)="getUsersLastPage()"
	(openChangePasswordModal)="openChangePasswordModal($event)">
</app-table>

<app-change-password-modal
  [userId]="selectedUserId"
  [userEmail]="selectedUserEmail"
  [isOpen]="isPasswordModalOpen"
  (closeModal)="closeChangePasswordModal()">
</app-change-password-modal>

<!-- <app-modal 
    [isOpen]="isModalOpen"
    [heading]="'echece'"
    [subHeading]="'zgezf gzz'" 
    (closeModal)="isModalOpen=false" 
    [icon]="modalIcon"
    >
</app-modal> -->
