<div class=" sm:py-2 sm:px-3 h-full  bg-white border border-[2px] border-slate-500	 rounded-xl">
	<span class="sm:flex justify-between">
		<span class="font-semibold text-sm">{{'posts.channel'|translate}} :</span> 
		<button 
			(click)="toggleLinkedIn()"
			type="button"
			[ngClass]="{
				'bg-blue-600':currentPlatforms.isLinkedinON,
				'bg-gray-100':!currentPlatforms.isLinkedinON,
				'cursor-not-allowed':CheckedCount()==1 && currentPlatforms.isLinkedinON
			}" 
			[disabled]="CheckedCount()==1 && currentPlatforms.isLinkedinON"
			class="px-1 rounded-full "
		>
			<img class="w-20 m-auto" [src]="currentPlatforms.isLinkedinON?'assets/img/linkedin-logo.png':'assets/img/linkedin-logo-disabled.png'" alt="linkedin">
		</button>

		<button 
			(click)="toggleGoogle()"
			type="button"
			[ngClass]="{
				'bg-orange-600':currentPlatforms?.isGoogleON,
				'bg-gray-100 ':!currentPlatforms?.isGoogleON,
				'cursor-not-allowed':CheckedCount()==1  && currentPlatforms?.isGoogleON
			}" 
			[disabled]="CheckedCount()==1 && currentPlatforms?.isGoogleON"
			class="px-1 rounded-full "
		>
			<img class="w-16 m-auto" [src]="currentPlatforms?.isGoogleON ?  'assets/img/google-logo.png': 'assets/img/google-logo-disabled.png'" alt="google">
		</button>

		<button 
			(click)="toggleFacebook() "
			type="button"
			[ngClass]="{
				'bg-sky-600':currentPlatforms?.isFacebookON,
				'bg-gray-100 ':!currentPlatforms?.isFacebookON,
				'cursor-not-allowed':CheckedCount()==1 && currentPlatforms?.isFacebookON
			}" 
			[disabled]="CheckedCount()==1 && currentPlatforms?.isFacebookON"
			class="px-1 rounded-full "
		>
			<img class="w-16 m-auto" [src]="currentPlatforms?.isFacebookON? 'assets/img/facebook-logo.png': 'assets/img/facebook-logo-disabled.png'" alt="facebook">
		</button>

		<button 
			type="button"
			(click)="toggleInstagram()"
			[ngClass]="{
					'bg-pink-600':currentPlatforms?.isInstagramON,
					'bg-gray-100 ':!currentPlatforms?.isInstagramON,

					'cursor-not-allowed':CheckedCount()==1 &&  currentPlatforms?.isInstagramON
			}" 
			[disabled]="CheckedCount()==1 && currentPlatforms?.isInstagramON"
			class="px-1 rounded-full "
		>
			<img class="w-16 m-auto" [src]="currentPlatforms?.isInstagramON?'assets/img/instagram-logo.png':'assets/img/instagram-logo-disabled.png'" alt="instagram">
		</button>
	</span>
</div>
