import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/utilities/auth.service';
import Swal from 'sweetalert2';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';
import { Debug } from 'src/app/utils/debug';
import { PostService } from 'src/app/services/repositories/post.service';
import { UserService } from 'src/app/services/repositories/user.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  env = environment;
  recoveryPassword: boolean = false;
  emailError: string | null = null;
  emailValid: string | null = null;
  resetPasswordToken: string | null = 'null';
  currentUserSubject: any;
  alreadyclicked: boolean = false
  constructor(
    private authService: AuthService,
    private router: Router,
    private translationService: TranslationService,
    private postService: PostService,
    private userService: UserService,
  ) {}

  login(username: string, password: string, event: Event): void {
    event.preventDefault();
    if(!this.alreadyclicked){
      this.alreadyclicked = true;
      this.authService.login(username, password).subscribe({
        next: () => {
          this.authService.loadCurrentUser().subscribe({
            next: (user: any) => {
              let groupScopes: any[] = [];
      
              // Recursive function to collect all IDs
              const collectGroupIds = (group: any) => {
                groupScopes.push(group.id); // Add the current group ID
                if (group.children && group.children.length > 0) {
                  group.children.forEach((child: any) => collectGroupIds(child)); // Recursively add children
                }
              };
      
              // Iterate through accreditations to get group and children IDs
              user.data.accreditations.forEach((accreditation: any) => {
                collectGroupIds(accreditation.group);
              });
              this.postService.saveGroupScopesToLocalStorage(groupScopes);
              this.router.navigate(['/home']);
              this.alreadyclicked = false;
            },
            error: (err) => {
              console.error('Error loading current user:', err);
            },
          });
        },
        error: (err) => {
          console.error('Login error:', err);
        },
      });
    }
  }
  switchEn() {
    this.translationService.setLanguage('en');
  }

  switchFr() {
    this.translationService.setLanguage('fr');
  }

  resetPassword(email: string, event: Event): void {
    if (!this.isValidEmail(email)) {
      this.emailValid = null;
      this.emailError = "generic.invalidEmail";
      return;
    } else {
      this.emailError = null;
      this.emailValid = "generic.emailNotice";
      this.authService.resetPasswordEmail(email).subscribe();
    }
  }

  isValidEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière simple pour la validation d'e-mail
    return emailPattern.test(email);
  }

  recovery(){
    this.recoveryPassword =  !this.recoveryPassword
  }
}
