import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-home-widget-planned',
  templateUrl: './home-widget-planned.component.html',
  styleUrls: ['./home-widget-planned.component.scss']
})
export class HomeWidgetPlannedComponent extends BaseComponent {
  constructor(
    translationService: TranslationService,
  ) {
    super(translationService);
  }
  linkedin : boolean = false;
  google : boolean = false;
  facebook : boolean = false;
  instagram : boolean = false;
  linkedinData: any = null;
  linkedinMedias: any[] = []
  googleData: any = null;
  googleMedias: any[] = []
  facebookData: any = null;
  facebookMedias: any[] = []
  instagramData: any = null;
  instagramMedias: any[] = []
  mediaAllpreview: any =  null;
  containerHeight: number = 0;
  containerWidth: number = 0;
  postHour: string = '00';
  postMinute: string = '00';
  groups: any[] = [];
  @Input() currentGroups: any;
  @Input() planned: any;

  ngOnInit(){
    this.planned.SocialPlatforms.forEach((item: { source: string; medias: any, group: any })=>{
      if (!this.groups.some(group => group.id === item.group.id) && this.currentGroups.includes(item.group.id)){
        this.groups.push({id: item.group.id, name: item.group.name, logo: item.group.logoUrl})
      }
      if(item.source == "linkedin"){
        this.linkedin = true;
        if(item.medias.length>0)
          this.googleMedias = item.medias;
      }
      if(item.source == "google"){
        this.google = true;
        if(item.medias.length>0)
          this.linkedinMedias = item.medias;
      }
     if(item.source == "facebook"){
      this.facebook = true;
      if(item.medias.length>0)
        this.facebookMedias = item.medias;
      }
     if(item.source == "instagram"){
      this.instagram = true;
      if(item.medias.length>0)
        this.instagramMedias = item.medias;
      }
    })
    this.choiceMediaPreview();
    const date = new Date(this.planned?.expectedPublishingDatetime);
    this.postHour = String(date.getUTCHours()).padStart(2, '0');
    this.postMinute = String(date.getUTCMinutes()).padStart(2, '0');
    // console.log('palnned', this.planned);
    const container = document.getElementById('container');
    if (container){
      this.containerHeight = container.clientHeight;
      this.containerWidth = container.clientWidth;
    }
  }
  choiceMediaPreview(){
    if(this.googleMedias.length > 0){
      this.mediaAllpreview = this.googleMedias[0];
      return
    }
    
    if(this.linkedinMedias.length > 0){
      this.mediaAllpreview = this.linkedinMedias[0];
      return
    }
    
    if(this.facebookMedias.length > 0){
      this.mediaAllpreview = this.facebookMedias[0];
      return
    }
    
    if(this.instagramMedias.length > 0){
      this.mediaAllpreview = this.instagramMedias[0];
      return
    }
  }

  calculatePastTimeFromNow() {
    let postTime = '';
    let futurePost = false;
    postTime = this.planned.expectedPublishingDatetime
    const postTimeUtc = new Date(postTime);

    const now = new Date(); // Current time

    let delta = Math.abs(now.getTime() - postTimeUtc.getTime()) / 1000; // Delta in seconds

    if (postTimeUtc > now) {
      // Post is in the future
      futurePost = true;
    }

    // Calculate differences and round up if necessary
    let unit = '';
    let value = 0;

    if (delta >= 3600 * 24 * 365) {
      value = Math.ceil(delta / (3600 * 24 * 365));
      unit = 'year';
    } else if (delta >= 3600 * 24 * 30) {
      value = Math.ceil(delta / (3600 * 24 * 30));
      unit = 'month';
    } else if (delta >= 3600 * 24) {
      value = Math.ceil(delta / (3600 * 24));
      unit = 'day';
    } else if (delta >= 3600) {
      value = Math.ceil(delta / 3600);
      unit = 'hour';
    } else if (delta >= 60) {
      value = Math.ceil(delta / 60);
      unit = 'minute';
    } else {
      value = Math.ceil(delta);
      unit = 'second';
    }

    // Format output
    let output = `${this.getTranslation(
      futurePost ? 'generic.PostedIn' : 'generic.hasbeen'
    )} ${value} ${this.getTranslation(
      `generic.short-${unit}${value > 1 ? 's' : ''}`
    )} ${this.getTranslation('generic.on')} `;
    return output.trim();
  }

}
