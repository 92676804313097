export const environment = {
  production: true,
  env: 'production',
	
  // this needs to be changed in heroku's env variables as well: API_URL and APP_URL
	// custom domain:
	baseUrl: 'https://www.cliking-social-media.app',
  apiUrl: 'https://www.cliking-social-media.app/api/v1',
  apiUrlRegex: /https:\/\/www\.cliking-social-media\.app\/api\/v1/,
	// herokuapp:
  // apiUrl: 'https://social-media-cliking-452047f08e1b.herokuapp.com/api/v1',
  // apiUrlRegex: /https:\/\/social-media-cliking-452047f08e1b\.herokuapp\.com\/api\/v1/,

	sessionData: 'sessionData',
};
