import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/utilities/auth.service';


@Component({
  selector: 'app-login-activities-manager',
  templateUrl: './login-activities-manager.component.html',
  styleUrls: ['./login-activities-manager.component.scss']
})
export class LoginActivitiesManagerComponent {
  logs: any ;

  constructor(
    private authService: AuthService,
  ) {}
  
  ngOnInit(){
    this.authService.allLoginActivities().subscribe((res: any)=>{
      this.logs =  res
    })
  }

  deleteLog(data: any) {
    this.authService.deleteLog(data.item.id).subscribe();
    this.authService.allLoginActivities().subscribe((res: any)=>{
      this.logs =  res
    })
  }
}
