import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/utilities/translation.service';

@Pipe({
    name: 'timeStampToHumanReadable',
  })
  export class TimeStampToHumanReadablePipe implements PipeTransform {
    constructor(private ts: TranslationService){}
    transform(timeStampString:string):string { 
        
        // Check if the timestamp is valid
    const date = new Date(timeStampString);
        if (isNaN(date.getTime())) {
        return 'Invalid Date'; // Return this if the timestamp is invalid
        }

        // Format the date to "12 November at 10:15"
        const day = date.getDate();
        const month = date.getMonth()+1; // Full month name
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero

        return `${day} ${this.ts.t('generic.month'+month)} ${this.ts.t('generic.at')} ${hours}:${minutes}`;
    }
  }