import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SocialMediaService } from 'src/app/services/repositories/social-media.service';
import { GoogleErrorsService } from 'src/app/services/utilities/google-errors.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { HoursFormComponent } from './hours-form/hours-form.component';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-manage-page',
  templateUrl: './manage-page.component.html',
  styleUrls: ['./manage-page.component.scss'],
})
export class ManagePageComponent extends BaseComponent {
  @ViewChild('description') description!: ElementRef;
  @ViewChild(HoursFormComponent) hoursFormComponent!: HoursFormComponent;

  pageForm!: FormGroup;
  creationStep: number = 0;

  pageIds: any | null = null;
  id: string | null = null;
  page: any | null = null;
  pages: any[] = [];
  multiEdit: boolean = false;
  selectedCategory!: { name: string; displayName: string };
  selectedAddress!: any;
  user!: any;

  // previewVariables:{medias: string[], title: string, primaryCategory: string, address: string, description: string, phone: string} = {medias: [], title: "", primaryCategory: "", address: "", description: "", phone: ""}

  showTooltipVisibility: boolean = false;

  constructor(
    translationService: TranslationService,
    private route: ActivatedRoute,
    protected socialMediaService: SocialMediaService,
    protected googleErrorsService: GoogleErrorsService,
    protected authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    super(translationService);
  }

  ngOnInit() {
    // Retrieve query parameters
    this.authService.loadCurrentUser().subscribe((user: any) => {
      this.user = user.data;
      Debug.log('this.user', this.user);
    });
    this.route.queryParamMap.subscribe((params) => {
      const selectedIdsParam = params.get('pageIds');
      if (selectedIdsParam) {
        this.pageIds = selectedIdsParam.split(',').map((id) => +id); // Convert to number array
        // this.multiEdit = this.pageIds.length > 1; // No 'real' multi edits for now
      } else {
        this.multiEdit = false;
      }
    });
    Debug.log('this.pageIds', this.pageIds);
    this.initializeForm();
    // Debug.log(this.pageForm);
    console.log(this.pageForm);

    if (!this.multiEdit) {
      this.id = this.pageIds[0];
      if (this.id) {
        // Logic to load the existing model for editing
        this.socialMediaService.getGooglePage([this.id]).subscribe((res) => {
          console.log('Social media data: ', res.data);
          console.log(
            'morehourtypes: ',
            res.data.categories?.primaryCategory?.moreHoursTypes
          );
          this.page = res.data;
          this.updateFormValues(this.page);
          this.selectedAddress = this.page.storefrontAddress;
          this.updateSelectedCategory(this.page);
        });
      } else {
        // Logic for creating a new instance
      }
    } else {
      //multisite
      this.fetchPages();
    }
  }

  fetchPages(): void {
    if (this.pageIds.length > 0) {
      // const observables = this.pageIds.map((id:number) => this.socialMediaService.getSocialMedia(id));
      const observables = this.pageIds.map((id: number) =>
        this.socialMediaService.getGooglePage([id])
      );
      forkJoin(observables).subscribe((responses: any) => {
        this.pages = responses.map((res: any) => res.data);
        Debug.log(this.pages);
      });
    }
  }

  initializeForm() {
    this.pageForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(1)]],
      description: ['', [Validators.minLength(1)]],
      phone: ['', []],
      webSite: ['', []],
      address: ['', []],
    });
  }

  updateFormValues(pageData: any) {
    let address;
    let phoneNumber;
    let webSite;
    if (pageData.storefrontAddress) {
      address =
        pageData.storefrontAddress.addressLines[0] +
        ', ' +
        pageData.storefrontAddress.postalCode +
        ' ' +
        pageData.storefrontAddress.locality;
    }
    if (pageData.phoneNumbers) {
      phoneNumber = pageData.phoneNumbers.primaryPhone;
    }
    if (pageData.websiteUri) {
      webSite = pageData.websiteUri;
    }
    this.pageForm.patchValue({
      title: pageData.title,
      description: pageData.profile?.description,
      address: address,
      phone: phoneNumber,
      webSite: webSite,
      // Update other form controls similarly
    });
    this.resizeDescriptionInput();
    // Debug.log('this.pageForm', this.pageForm);
    console.log('this.pageForm', this.pageForm);
  }

  updateSelectedCategory(pageData: any) {
    const name = pageData.categories.primaryCategory.name;
    const displayName = pageData.categories.primaryCategory.displayName;
    this.selectedCategory = { name: name, displayName: displayName };
  }

  onSubmit() {
    const updatedHours = this.hoursFormComponent?.getCurrentHours();

    const regularHours = updatedHours
      ? updatedHours.regularHours
      : this.page.regularHours;
    const specialHours = updatedHours
      ? updatedHours.specialHours
      : this.page.specialHours;
    const moreHours = updatedHours
      ? updatedHours.moreHours
      : this.page.moreHours;
    const openInfo = updatedHours ? updatedHours.openInfo : this.page.openInfo;

    Swal.fire({
      title: this.translationService.t('pages.pageUpdateInProgress'),
      text: this.translationService.t('generic.pleaseWait'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const pageData = {
      ...this.pageForm.value,
      category: this.selectedCategory.name,
      address: this.selectedAddress,
      regularHours: regularHours,
      specialHours: specialHours,
      moreHours: moreHours,
      openInfo: openInfo,
    };

    if (this.id) {
      this.socialMediaService.patchGooglePage(this.id, pageData).subscribe({
        next: (res) => {
          Swal.fire({
            icon: 'success',
            title: this.getTranslation('pages.editedSuccessfully'),
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.router.navigate(['/presence-management']); // Comment to stay on the page after post creation
          });
        },
        error: (error) => {
          Debug.log('getLanguage:', this.translationService.getLanguage());
          const errorDetails =
            error.error?.error
              ?.map((e: any) =>
                this.googleErrorsService.getErrorMessage(
                  e,
                  this.translationService.getLanguage()
                )
              )
              .join('<br> ') || 'Unknown error';
          Swal.fire({
            icon: 'error',
            title: this.translationService.t('pages.errorUpdatingGooglePage'),
            html: errorDetails,
            confirmButtonText: 'OK',
            customClass: {
              confirmButton:
                'bg-blue-500 text-white border-none px-4 py-2 text-lg rounded-md cursor-pointer',
            },
            buttonsStyling: false,
          });

          // Optionally log the error here if you want to handle the frontend logging
          this.socialMediaService.logApiError('GooglePageUpdateError', {
            pageData,
            errorDetails,
          });
        },
      });
    }
  }

  resizeDescriptionInput() {
    Debug.log('resizeDescriptionInput');
    if (this.description) {
      const textarea = this.description.nativeElement;
      // Reset textarea height to auto to calculate new height
      textarea.style.height = 'auto';

      // Calculate the new height
      const newHeight = textarea.scrollHeight;
      Debug.log('newHeight:', newHeight);

      // Set the new height, but not exceeding the max height
      const maxHeight = textarea.parentElement.parentElement.clientHeight;
      Debug.log('maxHeight:', maxHeight);
      textarea.style.height = `calc(${Math.min(
        newHeight,
        maxHeight
      )}px - 3.5rem)`;

      // Add overflow if the content exceeds the max height
      if (newHeight > maxHeight) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  }

  prevStep() {
    if (this.creationStep > 0) {
      this.creationStep -= 1;
    }
  }

  nextStep() {
    if (this.creationStep < 2) {
      this.creationStep += 1;
    }
  }

  changeStep(step: number) {
    if (step < 3 && step >= 0) {
      this.creationStep = step;
    }
  }

  onAddressSelected(address: any) {
    Debug.log('Selected address:', address);
    // You can now use this address to send to your backend or directly to Google My Business API
    this.selectedAddress = address;
  }

  refreshLocationData() {
    Debug.log('refreshLocationData');
    if (this.id) {
      this.socialMediaService.refreshLocationData(this.id).subscribe((res) => {
        location.reload();
      });
    }
  }

	clearMultiSelect(){
		this.pageIds = null;
		this.multiEdit = false;
	}
}
