<div 
	class="bg-white text-slate-700 w-full font-normal py-2 "
	[ngClass]="{
		'rounded-2xl': !dashboard,
		'rounded-b-2xl': dashboard,
	}"
>
	<div class="flex items-start text-sm font-semibold gap-2 pt-3 pl-4"  > <!--drop down-->
			<span class="mt-2">{{'generic.filter'|translate}} : </span>
			<div class=" border border-slate-300 rounded-xl p-2" *ngIf="!isRouteOnDashboard">
				<app-date-filter/>
			</div>

			<app-drop-down-list-items 
				*ngIf="!isRouteOnDashboard"
				[label]="'generic.platform'" 
				[tailWindClasses]="' relative border border-slate-300 rounded-xl p-2'" 
				[items]="['Linkedin','Google','Facebook','Instagram']" (itemToggled)="updatePlatforms($event)"
				[checkedItemsStatus]="platforms"
			/>

			<app-drop-down-list-items 
				[label]="'generic.postTypes'" 
				[tailWindClasses]="' relative border border-slate-300 rounded-xl p-2'" 
				[items]="!isRouteOnDashboard?['generic.posted','generic.planned','generic.draft']:['generic.posted','generic.planned']" (itemToggled)="updatePostType($event)"
				[checkedItemsStatus]="postTypes"
			/>
			<span *ngIf="isRouteOnDashboard" class="flex flex-wrap gap-2 mt-2">
				<!-- post types -->
				<span class="text-gray-600 bg-green-50 p-1 rounded-full border  border-green-300 flex items-start gap-1" *ngIf="postTypes.isPosted" >
					<svg  width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg>
					<span >{{'generic.posted'|translate}}</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePostType('generic.posted')">✕</span>
				</span>

				<span class="text-gray-600 bg-yellow-50 p-1 rounded-full border  border-yellow-300 flex items-start gap-1" *ngIf="postTypes.isPlanned" >
					<svg width="18"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock-3"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16.5 12"/></svg>
					<span >{{'generic.planned'|translate}}</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePostType('generic.planned')">✕</span>
				</span>

				<span class="flex gap-1 cursor-pointer text-gray-700" (click)="resetFilters()">
					<span class="mt-1 underline underline-offset-4"> {{'generic.clearAll'|translate}}</span>
					<svg  width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12"   cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
				</span>
			</span>
	</div>

	<div class="mt-3 flex flex-wrap text-xs px-3 py-2 gap-2 font-semibold relative" *ngIf="!isRouteOnDashboard">
			<!-- date range -->
			<span class="text-gray-600 bg-indigo-50 p-1 rounded-full border  border-indigo-300 flex items-start gap-1">
					<svg  width="18"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M8 2v4"/><path d="M16 2v4"/><rect width="18" height="18" x="3" y="4" rx="2"/><path d="M3 10h18" /></svg>
					<span class="pr-1">
							{{getSelectedDateRange()}}
					</span>
			</span>
			<!-- platforms -->
			<span class="text-blue-600 bg-blue-50 p-1 rounded-full border  border-blue-300 flex items-start gap-1"  *ngIf="platforms.isLinkedinON" >
					<img
					class="rounded-full w-4 inline"
					src="assets/img/simple-logo-linkedin.png"
					alt="linkedin"
					/>
					<span >Linkedin</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePlatforms('Linkedin')">✕</span>
			</span>

			<span class="text-orange-600 bg-orange-50 p-1 rounded-full border  border-orange-300 flex items-start gap-1"  *ngIf="platforms.isGoogleON">
					<svg class="p-1 bg-orange-600 rounded-full" width="18px"  viewBox="0 0 48 48"  fill="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <path d="M24.7,20.5v7.6H35.6a10.9,10.9,0,0,1-10.9,8,12.1,12.1,0,1,1,7.9-21.3l5.6-5.6A20,20,0,1,0,24.7,44c16.8,0,20.5-15.7,18.9-23.5Z"></path> </g> </g> </g></svg>
			
					<span >Google</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePlatforms('Google')">✕</span>
			</span>

			<span class="text-blue-700 bg-blue-50 p-1 rounded-full border  border-blue-400 flex items-start gap-1"  *ngIf="platforms.isFacebookON">
					<img
					class="rounded-full w-4 inline"
					src="assets/img/simple-logo-facebook.png"
					alt="facebook"
					/>
					<span >Facebook</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePlatforms('Facebook')">✕</span>
			</span>

			<span class="text-pink-600 bg-pink-50 p-1 rounded-full border  border-pink-300 flex items-start gap-1"  *ngIf="platforms.isInstagramON" >
					<img
					class="rounded-full w-4 inline"
					src="assets/img/instagram.svg"
					alt="instagram"
					/>
					<span >Instagram</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePlatforms('Instagram')">✕</span>
			</span>

			<!-- post types -->
			<span class="text-gray-600 bg-green-50 p-1 rounded-full border  border-green-300 flex items-start gap-1" *ngIf="postTypes.isPosted" >
					<svg  width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check"><circle cx="12" cy="12" r="10"/><path d="m9 12 2 2 4-4"/></svg>
					<span >{{'generic.posted'|translate}}</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePostType('generic.posted')">✕</span>
			</span>

			<span class="text-gray-600 bg-yellow-50 p-1 rounded-full border  border-yellow-300 flex items-start gap-1" *ngIf="postTypes.isPlanned" >
					<svg width="18"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock-3"><circle cx="12" cy="12" r="10"/><polyline points="12 6 12 12 16.5 12"/></svg>
					<span >{{'generic.planned'|translate}}</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePostType('generic.planned')">✕</span>
			</span>
	
			<span class="text-gray-600 bg-gray-50 p-1 rounded-full border  border-gray-300 flex items-start gap-1" *ngIf="postTypes.isDraft" >
						<svg width="18"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-dashed"><path d="M10.1 2.182a10 10 0 0 1 3.8 0"/><path d="M13.9 21.818a10 10 0 0 1-3.8 0"/><path d="M17.609 3.721a10 10 0 0 1 2.69 2.7"/><path d="M2.182 13.9a10 10 0 0 1 0-3.8"/><path d="M20.279 17.609a10 10 0 0 1-2.7 2.69"/><path d="M21.818 10.1a10 10 0 0 1 0 3.8"/><path d="M3.721 6.391a10 10 0 0 1 2.7-2.69"/><path d="M6.391 20.279a10 10 0 0 1-2.69-2.7"/></svg>
					<span >{{'generic.draft'|translate}}</span>
					<span class="cursor-pointer pr-1 font-semibold" (click)="updatePostType('generic.draft')">✕</span>
			</span>

<!-- 
			<span class="flex gap-1 cursor-pointer text-gray-700" (click)="resetFilters()">
					<span class="mt-1 underline underline-offset-4"> {{'generic.clearAll'|translate}}</span>
					<svg  width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-x"><circle cx="12"   cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
			</span> -->
			
	</div>
</div>
