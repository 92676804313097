import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractPostTimeStamp'
})
export class ExtractPostTimeStampPipe implements PipeTransform {

  transform(post: any, typeString: string): string {
    // Check if the post object is valid
    if (!post) {
      return 'Invalid Post';
    }

    // Handle different types of timestamps
    switch (typeString) {
      case 'post':
        return post.publishingDatetime || 'Invalid Timestamp';
      case 'planned':
        return post.expectedPublishingDatetime || 'Invalid Timestamp';
      case 'draft':
        return post.createdAt || 'Invalid Timestamp';
      default:
        return 'Invalid Post Type';
    }
  }

}
